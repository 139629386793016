<template>
   <section class="cont operate-box">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>营销云</el-breadcrumb-item>
            <el-breadcrumb-item>智能营销</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="oper-content-box">
         <!-- 查询框 -->
         <el-row class="oper-search-box" style="padding:0;padding-bottom:10px">
            <el-row>
               <el-row class="oper-search-item">
                  <el-button class="bg-gradient" @click="$router.push('/a_key_marketing')">一键营销</el-button>
               </el-row>
            </el-row>
         </el-row>
         <!-- tag-box -->
         <el-row class="tag-box">
            <el-menu default-active="1" class="el-menu-demo" mode="horizontal">
               <el-menu-item index="1" @click="getTempList(1)">全部</el-menu-item>
               <el-menu-item index="2" @click="getTempList(1, 'YES')">已完成</el-menu-item>
               <el-menu-item index="3" @click="getTempList(1, 'NO')">未开始</el-menu-item>
            </el-menu>
         </el-row>
         <!-- 主体内容 -->
         <el-row class="list" v-if="tempData.length">
            <el-row class="template" v-for="item in tempData" :key="item.id">
               <el-row class="title">{{item.tempName}}</el-row>
               <el-row class="template-content">
                  <el-col style="width:300px" class="date">
                     <p>创建时间：{{item.createTime}}</p>
                     <p>发送时间：{{item.startTime}}</p>
                     <p>操作人员：{{item.refUserName}}</p>
                  </el-col>
                  <el-col style="width:200px">
                     <p>目标人群条件：</p>
                     <br>
                     <div v-html="item.touchStrName"></div>
                     <div v-html="item.propStrName"></div>
                     <!-- <p>下单数>{{item.orderNumber}}</p>
                     <p>消费金额>{{item.price}}</p>
                     <p>性别={{item.sex}}</p> -->
                  </el-col>
                  <el-col style="width:400px">
                    <el-row :gutter="22" v-if="item.isCompleted == 'YES'">
                     <el-col :span="11">
                        <p>满足人数：{{item.allNumber ? item.allNumber : 0}}</p>
                        <br>
                        <p v-if="item.isDouyin == 'YES'">抖音：{{item.douyinNumber || 0}}</p>
                        <p v-if="item.isApplet == 'YES'">小程序：{{item.appletNumber || 0}}</p>
                        <p v-if="item.isOfficialAccounts == 'YES'">公众号：{{item.officialAccountsNumber || 0}}</p>
                        <p v-if="item.isNote == 'YES'">短信：{{item.noteNumber || 0}}</p>
                     </el-col>
                     <el-col :span="10">
                        <el-row :id="'meet-number'+item.id" style=" height: 300px;margin-top:-90px"></el-row>
                     </el-col>
                    </el-row>
                    <el-row :gutter="22" v-else>
                     <el-col :span="11">
                        <p>满足人数：{{item.allNumber}}</p>
                        <br>
                        <p style="color:#909399"><i class="el-icon-info"></i>&nbsp;&nbsp;活动未开始</p>
                     </el-col>
                    </el-row>
                  </el-col>
                  <el-col style="width:300px;min-height:1px">
                     <el-row :gutter="12" style="line-height:120px" v-if="item.isCompleted == 'YES'">
                        <el-col :span="6">成功率：</el-col>
                        <el-col :span="6"><el-progress :stroke-width="20" type="circle" :percentage="item.successRate * 100"></el-progress></el-col>
                     </el-row>
                  </el-col>
                  <el-col class="success" style="width:250px">
                     <el-divider class="border" direction="vertical"></el-divider>
                     <span>{{item.isCompleted == 'YES' ? '已完成' : '未开始'}}</span>
                  </el-col>
               </el-row>
            </el-row>
             <!-- 分页 -->
          <pagination :total="total" :currentPage="page" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="changeCurrPage"/>
         </el-row>
         <el-empty :image="require('@/assets/group_empty.png')" v-else></el-empty>
      </el-row>
   </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
   name: 'smartMarketing',
   data(){
      return{
         that: this,
         total: 0,
         page: 1,
         limit: 10,

         pieOptions: {
               color: [ '#ec5236', '#2ee4bb', '#0474fe', '#ffa333' ],
               tooltip: {
                  trigger: 'item',
                  formatter: '{a} <br/>{b} : {c} ({d}%)'
               },
               series: [
                  {
                     name: '满足人数',
                     type: 'pie',
                     // radius: ['50%', '70%'],
                     avoidLabelOverlap: false,
                     label: {
                        show: false,
                        position: 'center'
                     },
                     labelLine: {
                        show: false
                     },
                     data: []
                  }
               ]
            },
         
         tempData: [],
         status: "",
      }
   },
   computed: {
      ...mapState(['hotelInfo'])
   },
   mounted() {
      this.getTempList()
   },
   methods: {
      // 获取满足人数
      getSaleData(){
         for (let i in this.tempData) {
            if (this.tempData[i].isCompleted == 'YES') {
               this.$echarts.init(document.getElementById('meet-number' + this.tempData[i].id))
            }
         }
         let pieOption = this.pieOptions
        
         for (let i in this.tempData) {
            if (this.tempData[i].isCompleted == 'YES') {
               pieOption.series[0].data = []
               if (this.tempData[i].isApplet == 'YES') {
                  pieOption.series[0].data.push({value:this.tempData[i].appletNumber, name:'小程序'})
               }
               if (this.tempData[i].isDouyin == 'YES') {
                  pieOption.series[0].data.push({value:this.tempData[i].douyinNumber, name:'抖音'})
               }
               if (this.tempData[i].isNote == 'YES') {
                  pieOption.series[0].data.push({value:this.tempData[i].noteNumber, name:'短信'})
               }
               if (this.tempData[i].isOfficialAccounts == 'YES') {
                  pieOption.series[0].data.push({value:this.tempData[i].officialAccountsNumber, name:'公众号'})
               }
               this.$echarts.init(document.getElementById('meet-number' + this.tempData[i].id)).setOption(pieOption);
            }
         }     
      },

      // 获取智能营销模板列表
      getTempList(page, status) {
         if (page) {
            this.page = page
         }
         this.status = status
         this.$axios.post('/event/smartMarketing/getPage', 
         {limit: this.limit, page: this.page, hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId, isCompleted: this.status}).then(res => {
            if (res.success) {
               this.tempData = res.records
               this.total = res.total
               this.$nextTick(() => {
                  this.getSaleData()
               })
            }
         })
      },
         
      // 改变每页数
      changePageNum(num){
         this.limit = num
         this.getTempList(this.status)
      },
      // 改变当前页
      changeCurrPage(num){
         this.page = num
         this.getTempList(this.status)
      },
     
   },
   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id && oldVal) {
               this.getTempList(1, this.status)
            }
         }
      },  
   },
   filters: {
      
   }
}
</script>

<style scoped lang="scss">

   .tag-box {
      margin-bottom: 0;
      .el-menu-item {
         font-size: 16px;
      }
   }

   .template {
      background:rgb(242, 242, 242);
      padding:20px;
      height: 240px;
      margin: 30px 0;
      .title {
         font-size: 24px;
         font-weight: bold;
         margin-bottom: 10px;
      }

      .template-content {
         .date {
            p {
               line-height: 35px;
            }
         }
      }

      .success {
         right: 100px;
         margin-top: -40px;
         font-size: 22px;
         font-weight: bold;
         .border {
            background-color: black;
            height: 200px;
            margin-right: 50px;
         }
      }
   }
</style>
